import { createSlice } from '@reduxjs/toolkit';
//import { useHref } from 'react-router-dom';
const initialState = {
  value: 10,
  nama_pt: 'Datasoft Mediagraha',
  skema: 'demo',
  sudahLogin: false,
  isi_cetakan: '',
  dapat_buka_admin: false,
  width: 0,
  status: 'idle',
  appCheck: false,
  lainLain: [],
  induk1: [],
  dataakses: '',
  infoku: {
    nama: '',
    alamat: '',
    nilai: 0,
    gambarku: '',
  }
};
export const reduser = createSlice({
  name: 'generalState',
  initialState,
  reducers: {    
    ubahAppCheck: (state, action) => {
      state.appCheck = true;
    },        
    isiNilai: (state, action) => {
      state.nilai= {
        nama: action.payload.nama,
        alamat: action.payload.alamat,
        nilai: state.value+action.payload.nilai,
      }
      
    },
    theRoot: (state, action) => {
      state[action.payload.nama]= action.payload.nilai;      
    },
  },
  
});

export const { isiNilai,ubahAppCheck,theRoot } = reduser.actions;
export const isiState = (state) => state.generalState.isi_cetakan;
export const selectCount = (state) => state.generalState.value;
export const lihatInfoku = (state) => state.generalState.infoku;
export const lihatAppCheck = (state) => state.generalState.appCheck;
export const lihatAkses = (state) => state.generalState.dataakses;
export const lihatWidth = (state) => state.generalState.width;
export const skema = (state) => state.generalState.skema;
export const nama_pt = (state) => state.generalState.nama_pt;
export const sudahlogin = (state) => state.generalState.sudahLogin;

export default reduser.reducer;
