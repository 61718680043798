import React from "react";

const HalamanContoh = React.lazy(() => import("./halamanUtama/HalamanContoh"));
const TesQr = React.lazy(() => import("./edemo/TesQr"));
const WarrantyCheck = React.lazy(() => import("./eWarranty/WarrantyCheck"));
const PindahLokasi = React.lazy(() => import("./master/PindahLokasi"));
const Training = React.lazy(() => import("./eTraining/Training1"));
const Training2 = React.lazy(() => import("./eTraining/Training2"));
//const UserBaru = React.lazy(() => import("./master/UserBaru"));
const WarrantyList1 = React.lazy(() => import("./eWarranty/WarrantyList1"));
//const Ewarrantylama = React.lazy(() => import("./eWarranty/Ewarrantylama"));
const UploadBonus = React.lazy(() => import("./edemo/UploadBonus"));

const WarrantyList = React.lazy(() => import("./eWarranty/WarrantyList"));

const EdemoKunjungan = React.lazy(() => import("./edemo/EdemoKunjungan"));
const WarrantyReport1 = React.lazy(() => import("./eWarranty/EwarranyLaporan1"));
const PindahLokasiAcc = React.lazy(() => import("./master/PindahLokasiAcc"));

const EdemoLapSales = React.lazy(() => import("./edemo/EdemoLapSales"));
const EdemoGrupSales = React.lazy(() => import("./edemo/EdemoGrupSales"));
const EdemoGrupLeader = React.lazy(() => import("./edemo/EdemoGrupLeader"));
const EdemoGrupAsm = React.lazy(() => import("./edemo/EdemoGrupAsm"));
const EdemoLapCustomer = React.lazy(() => import("./edemo/EdemoLapCustomer"));
const EdemoDataCustomer = React.lazy(() => import("./edemo/EdemoDataCustomer"));
const EdemoLokasiDemo = React.lazy(() => import("./edemo/EdemoLokasiDemo"));

const Download = React.lazy(() => import("./master/Download"));

const DataSales = React.lazy(() => import("./master/DataSales"));
const Lokasi = React.lazy(() => import("./master/Lokasi"));

const PointWarranty = React.lazy(() => import("./master/PointWarranty"));
const ListService = React.lazy(() => import("./service/ListService"));
const ListInsentif = React.lazy(() => import("./service/ListInsentif"));
const ListDepo = React.lazy(() => import("./service/ListDepo"));
const DataUntukInsentif = React.lazy(() => import("./service/DataUntukInsentif"));
const DataServiceBulanan = React.lazy(() => import("./service/DataServiceBulanan"));
const DataRewardPenalti = React.lazy(() => import("./service/DataRewardPenalti"));

const MasterPoin = React.lazy(() => import("./service/MasterPoin"));
const MasterItemMasterPoin = React.lazy(() => import("./service/MasterItemMasterPoin"));


const NilaiBonusEdemo = React.lazy(() => import("./master/NilaiBonusEdemo"));

const PointEdemo = React.lazy(() => import("./edemo/PointEdemo"));
//const TesReader = React.lazy(() => import("./edemo/TesReader"));

const PointEdemoPromo = React.lazy(() => import("./edemo/PointEdemoPromo"));

const DataDc = React.lazy(() => import("./master/DataDc"));
const InsentifTeknisiTersimpan = React.lazy(() => import("./service/InsentifTeknisiTersimpan"));
const InsentifPeriode = React.lazy(() => import("./service/InsentifPeriode"));


//const TestInvoice2 = React.lazy(() => import("./invoice/TestInvoice2"));


//------------------

const Halaman1 = React.lazy(() => import("./halamanUtama/halaman1"));
const EWarranty = React.lazy(() => import("./eWarranty/Ewarranty"));

const Kunjungan = React.lazy(() => import("./edemo/Kunjungan"));

const MyProfile = React.lazy(() => import("./master/MyProfile"));

//const CashTeamDataPribadi = React.lazy(() => import("./cashteam/CashTeamDataPribadi"));
//{ path: "/tes-reader", name: "TesReader", component: TesReader },
//master-reminder-group
const routes2 = [              
    { path: "/masteritem-masterpoin", name: "MasterItemMasterPoin", component: MasterItemMasterPoin },
    { path: "/insentif-periode", name: "InsentifPeriode", component: InsentifPeriode },
{ path: "/master-poin", name: "MasterPoin", component: MasterPoin },
  { path: "/insentif-teknisi5", name: "InsentifTeknisiTersimpan", component: InsentifTeknisiTersimpan },
  { path: "/data-reward-penalti", name: "DataRewardPenalti", component: DataRewardPenalti },
  { path: "/data-service-bulanan", name: "DataServiceBulanan", component: DataServiceBulanan },
  { path: "/data-untuk-insentif", name: "DataUntukInsentif", component: DataUntukInsentif },
  { path: "/list-insentif", name: "ListInsentif", component: ListInsentif },
  { path: "/list-depo", name: "ListDepo", component: ListDepo },
  { path: "/list-service", name: "ListService", component: ListService },
  { path: "/edemo-lokasi-show", name: "EdemoLokasiDemo", component: EdemoLokasiDemo },
  { path: "/edemo-data-customer", name: "EdemoDataCustomer", component: EdemoDataCustomer },
  { path: "/edemo-lap-customer", name: "EdemoLapCustomer", component: EdemoLapCustomer },
  { path: "/edemo-group-leader", name: "EdemoGrupLeader", component: EdemoGrupLeader },
  { path: "/edemo-group-sales", name: "EdemoGrupSales", component: EdemoGrupSales },
  { path: "/edemo-group-asm", name: "EdemoGrupAsm", component: EdemoGrupAsm },
  
  { path: "/tes-qr", name: "TesQr", component: TesQr },
  { path: "/halaman-utama", name: "HalamanContoh", component: HalamanContoh },
  { path: "/upload-bonus", name: "UploadBonus", component: UploadBonus },  
  { path: "/point-edemo", name: "PointEdemo", component: PointEdemo },  
  { path: "/point-edemo-promo", name: "PointEdemoPromo", component: PointEdemoPromo },  
  
  //{ path: "/lokasi-cashteam", name: "LokasiCashteam", component: LokasiCashteam },  
  { path: "/pindah-lokasi-acc", name: "PindahLokasiAcc", component: PindahLokasiAcc },  
  { path: "/pindah-lokasi", name: "PindahLokasi", component: PindahLokasi },  
  
  
  { path: "/download-apk", name: "Download", component: Download },
  { path: "/edemo-list", name: "EdemoKunjungan", component: EdemoKunjungan },
  
  //{ path: "/list-data-pegawai", name: "ListDataPegawai", component: ListDataPegawai },
  //{ path: "/contekan", name: "Contekan", component: Contekan },
  
  { path: "/update-training", name: "Training2", component: Training2 },
  { path: "/input-training", name: "Training", component: Training },
  { path: "/my-profile", name: "MyProfile", component: MyProfile },
  { path: "/waranty-check", name: "WarrantyCheck", component: WarrantyCheck },
  { path: "/daftar-sales", name: "DataSales", component: DataSales },
  { path: "/daftar-lokasi", name: "Lokasi", component: Lokasi },
  // { path: "/cari-nip", name: "CariNip", component: CariNip },
  // { path: "/input-penjualan", name: "Penjualan1", component: Penjualan1 },
  // { path: "/input-penjualan2", name: "Penjualan2", component: Penjualan2 },
  { path: "/halaman-utama", name: "Halaman1", component: Halaman1 },
  
  { path: "/e-warranty", name: "EWarranty", component: EWarranty },
  
  
  { path: "/data-kunjungan", name: "Kunjungan", component: Kunjungan },
  

  //{ path: "/data-satuan", name: "DataSatuan", component: DataSatuan },
  
  // { path: "/data-jabatan", name: "DataJabatan", component: DataJabatan },
  
  //{ path: "/data-region", name: "DataRegion", component: DataRegion },
  { path: "/point-warranty", name: "PointWarranty", component: PointWarranty },
  { path: "/nilai-bonus-edemo", name: "NilaiBonusEdemo", component: NilaiBonusEdemo },
  { path: "/data-dc", name: "DataDc", component: DataDc },
  
  
  { path: "/waranty-list1", name: "WarrantyList1", component: WarrantyList1 },
  { path: "/waranty-list", name: "WarrantyList", component: WarrantyList},
  { path: "/waranty-report1", name: "WarrantyReport1", component: WarrantyReport1 },
  { path: "/edemo-lap-personal-sales", name: "EdemoLapSales", component: EdemoLapSales },
  
];

export default routes2;
