import React,{useEffect} from 'react'

import { Route, Routes,useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import {
  theRoot,
  lihatAppCheck,sudahlogin,
  ubahAppCheck,isiDataAkses,
} from './Store/reduser';

import {
  SyncOutlined,
} from '@ant-design/icons';
import './css/loadermenu.css'

import Koneksiku from './Services/Koneksiku';
import routes2 from './routes'
//import Ditunggu from "./Ditunggu";
const EWarranty2 = React.lazy(() => import("./eWarranty/Ewarranty2"));
const Login3 = React.lazy(() => import("./components/Login2"));
const Logout = React.lazy(() => import("./components/Logout3"));
const Master1 = React.lazy(() => import("./containers/master1"));
const Register = React.lazy(() => import("./components/Register"));
const LoginDirect = React.lazy(() => import("./components/LoginDirect"));
const EdemoBonus = React.lazy(() => import("./edemo/EdemoBonus"));

const loading = (
  <div id="loadingOverlay" class="loader-overlay">
    <div class="loader-content loader-center">
        <div class="loader-center loader-text">
        <SyncOutlined 
    style={{fontSize: 24}} spin /> <span style={{fontSize: 24}}> wait..</span>
        </div>        
    </div>
</div>
);

//const App = ({generalState,theRoot,app_kosong1,app_isi1}) => {
const App = () => {

  const appCheck = useSelector(lihatAppCheck);
  const sudahLogin = useSelector(sudahlogin);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const ceking=()=>{
    dispatch(ubahAppCheck());
      let te=Koneksiku.getLastLogin();
      if(te.hasil){
        //console.log('boleh1')
        const now = new Date();
        var lalu = new Date(te.tgl);        
        var diff = (now.getTime() - lalu.getTime()) / 1000;
        diff /= 60;
        let beda = Math.abs(Math.round(diff));
        
        if (beda > 1000) {
          Koneksiku.logout()
          return false;
          
        }else{
          return true;
        }
        
      }else{
        //console.log('boleh2')
        return false;
        //return <Navigate to={'login2/0'} replace />;
      }
    
    
  }
  
  useEffect(() => {
    
    if(!appCheck){
      //console.log('bison '+new Date())
      if(!ceking()){
        //console.log('boleh5')
        //navigate('/login2/0');      
      }else{
        //console.log('lesar',Koneksiku.getUser().nilai.namaalias);
        //console.log('lesar',new Date());

        
        const ka1=localStorage.getItem('dapat_buka_admin')
        if(ka1!==null){
          dispatch(theRoot({nama: 'dapat_buka_admin',nilai: Number(ka1)===0 ? false : true}));  
        }else{
          dispatch(theRoot({nama: 'dapat_buka_admin',nilai: false}));  
        }

        let k={
          nama: Koneksiku.getUser().nilai.namaalias.trim()==='' ? Koneksiku.getUser().nilai.name.trim() : Koneksiku.getUser().nilai.namaalias.trim(),
          alamat: '',
          nilai: 0,
          gambarku: (Koneksiku.getImageKu().hasil ? Koneksiku.getImageKu().gambar:''),
        }
        
        dispatch(theRoot({nama: 'infoku',nilai: k}));

        k=localStorage.getItem('induk1');
        if(k!==null){
          let te2=JSON.parse(k);
          let nama_pt=''
          
          for (let index = 0; index < te2.length; index++) {
            const el1 = te2[index];            
            if(el1.ket1.trim()==='NAMA USAHA'){
              nama_pt=el1.kd1.trim();
              break;
            } 
            
          }
          
          dispatch(theRoot({nama: 'nama_pt',nama_pt}));  
          dispatch(theRoot({nama: 'induk1',nilai: te2}));  
          //console.log('meta2 '+new Date(),te2)
        }

        
        if(Koneksiku.getAkses().hasil){
          dispatch(theRoot({
            nama: 'dataakses',
            nilai: Koneksiku.getAkses().nilai
          })); 

         
        }
        
      }
    }
  });

  return (

    <Routes>
      {/* <Route path="/tes" element={<Tes1 />} >
      </Route> */}
      {/* <Route path="*" element={<p>There's nothing here: 404!</p>} /> */}
      <Route path="/login2/:paramku" element={
        <React.Suspense fallback={loading}><Login3 /></React.Suspense>      
        } />

      <Route path="/evcr/:paramku" element={
        <React.Suspense fallback={loading}><EdemoBonus /></React.Suspense>      
        } />

      <Route path="/daftarwarranty/:paramku" element={
        <React.Suspense fallback={loading}><EWarranty2 /></React.Suspense>      
        } />                

      <Route path="/login-direct/:paramku" element={
        <React.Suspense fallback={loading}><LoginDirect /></React.Suspense>      
        } />                

      <Route path="/register" element={
        <React.Suspense fallback={loading}><Register /></React.Suspense>      
        } />              
      <Route path="/logout" element={<Logout />} />              
      <Route path="/" element={<Master1 />} >
        {routes2.map((route, idx) => {
            return route.component && (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                element={
                  <React.Suspense fallback={loading}>
                    <route.component/>
                  </React.Suspense>
                }
                //render={props => (<route.component {...props} />)} 
                // element={<route.component/>}
              />
            )
          })}         
          {/* </React.Suspense>  */}
      </Route>
      <Route path="*" 
      element={
        <React.Suspense fallback={<>...</>}>
          <NoMatch />
        </React.Suspense>
      }
      />

    </Routes>
      
    //  </React.Suspense>   
  )
}

const NoMatch = () => {
  return <p>There's nothing here: 404!</p>;
};

export default (App);

/*
  export default connect((state) => ({ generalState: state.generalState }), {
    theRoot,app_kosong1,app_isi1
  })(App);
*/